class VideoIntro {
    constructor(video, content, poster) {
        this.video = video;
        this.content = content;
        this.poster = poster;
    }

    init() {
        if (this.video) {
            this.handleLoad();
            this.handleTime();
        }
    }

    handleLoad() {
        this.video.addEventListener("loadeddata", (event) => {
            this.poster?.classList.add("loaded");
        });
    }

    // handleTime() {
    //     this.video.addEventListener("timeupdate", (event) => {
    //         this.content.forEach((element) => {
    //             if (
    //                 element.getAttribute("data-start-time") ===
    //                 this.video.currentTime.toFixed(0)
    //             ) {
    //                 element.classList.add("active");
    //             }
    //             if (
    //                 element.getAttribute("data-end-time") <=
    //                 this.video.currentTime.toFixed(0)
    //             ) {
    //                 element.classList.remove("active");
    //             }
    //         });
    //     });
    // }

    handleTime() {
        this.video.addEventListener("timeupdate", (event) => {
            this.content.forEach((element, index) => {
                if (
                    parseInt(element.getAttribute("data-start-time")) ===
                    parseInt(this.video.currentTime.toFixed(0))
                ) {
                    element.classList.add("active");
                }
                if (
                    parseInt(element.getAttribute("data-end-time")) <=
                    parseInt(this.video.currentTime.toFixed(0))
                ) {
                    element.classList.remove("active");
                }
            });
        });
    }
}

const video = new VideoIntro(
    document.getElementById("js-IntroVideo"),
    document.querySelectorAll(".js-IntroVideoText"),
    document.querySelector(".intro__bg-img")
);

video.init();
