import './bootstrap';
import Headroom from 'headroom.js';
import AOS from 'aos';
// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';
import "swiper/css/effect-fade"
import SmoothScroll from 'smooth-scroll';
import fslightbox from 'fslightbox';
import vhCheck from 'vh-check';

import './components/toastify';
import './components/form';
import './components/intro';

/*const header = document.getElementById('header-wrapper');
const headroom = new Headroom(header, {
    offset: 20,
});*/

// headroom.init();

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true,
});

const viewportHeight = vhCheck({
    cssVarName: 'vh-offset',
    bind: false,
});

window.onload = function () {
    AOS.init({
        offset: 0,
        // duration: 1000,
        delay: 0,
        once: true,
        anchorPlacement: 'top-center',
    });
};



import Swiper from 'swiper';
import { Autoplay ,Navigation, Pagination, EffectFade } from 'swiper/modules';

// Swiper sliders
const introSwiper = new Swiper('.swiper', {
    modules: [Autoplay ,Navigation, Pagination, EffectFade],

    spaceBetween: 30,
    effect: "fade",
    // your swiper options

    loop: true,

    autoplay: {
        delay: 2500,
        disableOnInteraction: true,
    },

    speed: 1000,
    fadeEffect: {
        crossFade: true
    },


    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});


/*ROLLING HEADER */


var lastScrollTop = 0;
var navbar = document.getElementById("mainNav");
var navbarNav = document.querySelector(".navbar-nav");

window.addEventListener("scroll", function () {
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    if (scrollTop > lastScrollTop) {
        navbar.style.top = "-200px";
    } else {
        navbar.style.top = "0";
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
}, false);